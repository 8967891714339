
import { mapGetters } from 'vuex'
// @group components

export default {
  name: 'TextBlockComponent',
  props: ['data'],

  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive',
      getPopupDatasTitle: 'ui/getPopupDatasTitle',
      parkInfo: 'ui/getPopupDatasParkInfo',
      location: 'ui/getPopupLocation',
    }),
    getPopupDatasDescription() {
      let obj = this._.cloneDeep(this.$store.state.ui.popupDatasDescription)
      return obj
    }
  },
  methods: {
    closeOverlay() {
      this.$store.commit('ui/toggleOverlay', false)
    }
  },
  watch: {
    isOverlayActive() {
      document.documentElement.classList[this.isOverlayActive ? 'add' : 'remove']('no-scroll')
    },
    $route() {
      this.closeOverlay()
    }
  },
}
