
import * as Cookie from 'tiny-cookie'

const STORAGE_TYPES = {
  local: 'localStorage',
  cookies: 'cookies'
}

export default {
  name: 'CookieLawComponent',
  props: {
    buttonDecline: {
      type: Boolean,
      default: false
    },
    /**
     * Cookie Container position
     * tl = topleft, tr = topright, bl = bottomleft, br = bottomright
     * @type {Object}
     */
    position: {
      type: String,
      default: 'bl'
    },
    /**
     * Transition style
     * fd = fade, ft = fromTop, fl = fromLeft, fb = fromBottom, fr = fromRight
     * @type {Object}
     */
    transition: {
      type: String,
      default: 'fd'
    },
    storageName: {
      type: String,
      default: 'cookie:accepted'
    },
    storageType: {
      type: String,
      default: STORAGE_TYPES.local
    },
    cookieOptions: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  data() {
    return {
      supportsLocalStorage: true,
      isOpen: false
    }
  },
  computed: {
    containerPosition() {
      return `position-${this.position}`
    },

    containerTransition() {
      return `transition-${this.transition}`
    },

    canUseLocalStorage() {
      return this.storageType === STORAGE_TYPES.local && this.supportsLocalStorage
    }
  },

  created() {
    if (this.storageType === STORAGE_TYPES.local) {
      // Check for availability of localStorage
      try {
        const test = '__vue-cookielaw-check-localStorage'
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(test, test)
          window.localStorage.removeItem(test)
        }
      } catch (e) {
        console.info('Local storage is not supported, falling back to cookie use')
        this.supportsLocalStorage = false
      }
    }

    if (process.client && !this.getVisited()) {
      this.isOpen = true
    }
  },

  mounted() {
    if (this.isAccepted()) {
      this.$emit('accept')
    }
  },

  methods: {
    setVisited() {
      if (this.canUseLocalStorage) {
        localStorage.setItem(this.storageName, true)
      } else {
        Cookie.set(this.storageName, true, { ...this.cookieOptions, expires: '1Y' })
      }
    },

    setAccepted() {
      if (this.canUseLocalStorage) {
        localStorage.setItem(this.storageName, true)
      } else {
        Cookie.set(this.storageName, true, { ...this.cookieOptions, expires: '1Y' })
      }
    },

    setDeclined() {
      if (this.canUseLocalStorage) {
        localStorage.setItem(this.storageName, false)
      } else {
        Cookie.set(this.storageName, false, { ...this.cookieOptions, expires: '1Y' })
      }
    },

    getVisited() {
      let visited = false
      if (this.canUseLocalStorage) {
        visited = localStorage.getItem(this.storageName)
      } else {
        visited = Cookie.get(this.storageName)
      }

      if (typeof visited === 'string') {
        visited = JSON.parse(visited)
      }
      return !(visited === null || visited === undefined)
    },

    isAccepted() {
      let accepted = false
      if (this.canUseLocalStorage) {
        accepted = localStorage.getItem(this.storageName)
      } else {
        accepted = Cookie.get(this.storageName)
      }

      if (typeof accepted === 'string') {
        accepted = JSON.parse(accepted)
      }

      return accepted
    },

    accept() {
      this.setVisited()
      this.setAccepted()
      this.isOpen = false
      this.$emit('accept')
    },

    close() {
      this.isOpen = false
      this.$emit('close')
    },

    decline() {
      this.setVisited()
      this.setDeclined()
      this.isOpen = false
      this.$emit('decline')
    },

    revoke() {
      if (this.canUseLocalStorage) {
        localStorage.removeItem(this.storageName)
      } else {
        Cookie.remove(this.storageName)
      }
      this.isOpen = true
      this.$emit('revoke')
    },

    open() {
      if (!this.getVisited()) {
        this.isOpen = true
      }
    }
  }
}
