/**
 * TODO: find a more polite fix for this
 * This middleware is a patch against nuxt/i18n module.
 * It's not behaving properly, so we need to handle locale manually.
 */
export default async function ({ store, route, isHMR, i18n, redirect }) {
  if (isHMR) return;

  const { fullPath } = route;
  const prefix = fullPath
    .split('/')
    .filter((slug) => slug !== '')[0];
  const isMatching = i18n.locales.some((l) => l.code === prefix);

  // workaround: defaultLocale has no prefix
  const locale = isMatching
    ? i18n.locales.find((locale) => locale.code === prefix).code
    : i18n.defaultLocale;

  store.commit('navigation/setLocale', locale);
  await store.dispatch('navigation/loadLocaleNavigation');
  await store.dispatch('navigation/loadStaticContents');

  /**
   * Redirect i18n.defaultLocale default if users / bots try to reach domain/{defaultLocale}/...
   */
  const hasUrlDefaultLocale = fullPath.indexOf(i18n.defaultLocale) > 0;
  const isDefaultLocale  = locale === i18n.defaultLocale;

  if (isDefaultLocale && hasUrlDefaultLocale) {
    const parsedUrl = fullPath.replace(`/${i18n.defaultLocale}`, "");
    const isHome = parsedUrl === "";
    redirect(isHome ? "/" : parsedUrl);
  }
}
