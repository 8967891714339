
// @group layout
export default {
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  }
}
