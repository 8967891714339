import {
  groq
} from '@nuxtjs/sanity';

export default function getNavigationQuery(locale) {
  let localizedMenu;
  /**
   * TODO: having this method to get the localized navigation
   * is not the best approach. We should reshape the Sanity navigation
   * object to have something more managable, hence more extendable.
   */
  switch (locale) {
    case 'de':
      localizedMenu = 'navigationMenuDe';
      break;
    case 'fr':
      localizedMenu = 'navigationMenuFr';
      break;
    default:
      localizedMenu = 'navigationMenuDe';
      break;
  }

  return groq`*[_type=="navigationSettings" && !(_id in path("drafts.**"))][0] {
    ${localizedMenu}[]{
      ...,
      firstLevel->{title, slug},
      secondLevel[]->{title, slug, parent->{slug}},
      jumplink {
        ...,
        reference->
      }
    }
  }`
}
