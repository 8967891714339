
import { mapGetters } from 'vuex'

export default {
  props: {
    to: {
      type: [String, Object],
      default: null
    },

    blank: {
      type: Boolean,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      sitemap: 'navigation/sitemap',
    }),
    isExternal() {
      return typeof this.to === 'string' && this.to.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/)
    },
    link() {
      const { page } = this.sitemap.find(({ page }) => page._id === this.to._ref );
      if (!!page) {
        return `${this.getLocalePrefix()}/${page.slug}`;
      }

      return `${this.getLocalePrefix()}`;
    }
  }
}
