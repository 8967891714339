const getSchema = (title, description, pageHref) => {
  return [
    { '@context': 'http://schema.org' },
    { '@type': 'WebPage' },
    { name: title },
    { description: description },
    { url: pageHref },
  ]
};

export default getSchema;