export const state = () => ({
  isOverlayActive: false,
  isLoaderActive: false,
  popupLocation: null,
  popupDatasDescription: null,
  popupDatasTitle: "",
  popupDatasParkInfo: [],
  popup: {
    map: {
      label: "",
      link: "",
    }
  },
})

export const mutations = {
  popupDatasDescription(state, value) {
    state.popupDatasDescription = value;
  },
  popupDatasTitle(state, value) {
    state.popupDatasTitle = value;
  },
  popupLocation(state, value) {
    state.popupLocation = value;
  },
  popupDatasParkInfo(state, value) {
    state.popupDatasParkInfo = value;
  },
  toggleOverlay(state, value) {
    state.isOverlayActive = value;
  },
  toggleLoader(state, value) {
    state.isLoaderActive = value;
  },
  popupLabel(state, value) {
    state.popup.map.label = value;
  },
  popupLink(state, value) {
    state.popup.map.link = value;
  }
}

export const getters = {
  getPopupDatasDescription(state) {
    return state.popupDatasDescription;
  },
  getPopupDatasTitle(state) {
    return state.popupDatasTitle;
  },
  getPopupLocation(state) {
    return state.popupLocation;
  },
  getPopupDatasParkInfo(state) {
    return state.popupDatasParkInfo;
  },
  getPopupMap(state) {
    return state.popup.map;
  },
  isOverlayActive(state) {
    return state.isOverlayActive;
  },
  isLoaderActive(state) {
    return state.isLoaderActive;
  }
}

export const strict = false