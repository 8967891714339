
// @group Partials
export default {
  props: {
    asset: {
      type: Object,
      default: null
    },
    alt: {
      type: [String, Object],
      default: null
    },
    refImage: {
      type: [String, Object],
      default: null
    },
    video: {
      type: [String, Object],
      default: null
    },
    title: {
      type: [String, Object],
      default: null
    },
    crop: {
      type: Object,
      default: null
    },
    hotspot: {
      type: Object,
      default: null
    },
    sizes: {
      type: Object,
      default() {
        return {
          sm: { viewportWidth: 100, ratio: 'default' },
          md: { viewportWidth: 100, ratio: 'default' },
          lg: { viewportWidth: 100, ratio: 'default' },
          xl: { viewportWidth: 100, ratio: 'default' },
          '2xl': { viewportWidth: 100, ratio: 'default' }
        }
      }
    }
  }
}
