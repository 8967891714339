module.exports = {
  mode: 'jit',
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      'sm': '0px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1280px',
      'max-w-bp': '1900px',
      '2xl': '1536px'
    },
    fontFamily: {
      // Add fonts
      sans: ['Work Sans', 'arial', 'sans-serif'],
    },
    grid: {
      containerXPaddingDesktop: '100px',
      containerXPaddingMobile: '22px',
      gapXDesktop: '45px',
      gapXMobile: '10px',
      gapYDesktop: '45px',
      gapYMobile: '30px'
    },
    extend: {
      maxWidth: (theme) => {
        return {
          content: '1900px',
          ...theme('spacing'),
        }
      },
      aspectRatio: {
        '4/3': '4/3',
        'square': '1/1'
      },
      colors: {
        palette: {
          // Add project colors
          white: '#fff',
          graydark: '#191919',
          red: 'red',
          yellow: '#FEF035',
          blue: '#B1E1F8',
          beige: '#FBF7F1'
        },
      },
      animation: {
        'spin-slow': 'spin 45s linear infinite'
      },
      dropShadow: {
        beige1Desktop: '40px -50px 0px #FBF7F1',
        beige1Mobile: '40px 50px 0px #FBF7F1'
      }
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('tailwindcss-selection-variant'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
  ],
  corePlugins: {
    container: false,
  },
}
