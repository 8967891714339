/**
 * The last part of 'urlParts' is the activeSlug to request to the backend.
 * pathMatch comes from $route.params
 */
export default {
  methods: {
    extractSlug(pathMatch) {
      const urlParts = pathMatch.split('/');
      const activeSlug = urlParts.length && urlParts[urlParts.length - 1];
      const isRootPage = activeSlug === ''
        || this.$i18n.locales.some((appLocale) => appLocale.code === activeSlug);

      return isRootPage
        ? '/'
        : activeSlug;
    },
  }
}
