
import imageUrlBuilder from '@sanity/image-url'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '~/tailwind.config'
import lazysizes from 'lazysizes'

import getImageDatasFromRef from '~/queries/getImageDatasFromRef'

// @group Partials
export default {
  props: {
    asset: {
      type: Object,
      default: null
    },
    alt: {
      type: [String, Object],
      default: null
    },
    refImage: {
      type: [String, Object],
      default: null
    },
    title: {
      type: [String, Object],
      default: null
    },
    crop: {
      type: Object,
      default: null
    },
    hotspot: {
      type: Object,
      default: null
    },
    sizes: {
      type: Object,
      default() {
        return {
          sm: { viewportWidth: 100, ratio: 'default' },
          md: { viewportWidth: 100, ratio: 'default' },
          lg: { viewportWidth: 100, ratio: 'default' },
          xl: { viewportWidth: 100, ratio: 'default' },
          '2xl': { viewportWidth: 100, ratio: 'default' }
        }
      }
    }
  },

  data() {
    return {
      config: {
        // Defines the max image size in pixels for "2xl" breakpoint
        imageMaxWidth: 2000
      },
      renderImage: false,
      imageValues: null,
      sanityImg: {
        asset: null,
        hotspot: null,
        crop: null,
        alt: null,
        width: null,
        height: null,
      }
    }
  },

  methods: {
    getAspectRatioHeight(width, ratio) {
      //This function is used to define the aspect ratio of the image according to the parameters we defined when calling this component
      if (ratio === 'square') {
        return width
      }

      if (ratio === 'panorama') {
        return Math.round((width / 147) * 36.75)
      }

      if (ratio.includes(':')) {
        const dimension = ratio.split(':')
        return Math.round((width / parseFloat(dimension[0])) * parseFloat(dimension[1]))
      }

      return Math.round(width / this.sanityImg.asset.metadata.dimensions.aspectRatio)
    },
    getCropImage() {
      //This function is used to crop the image according to the crop data received from Sanity
      let widthImage = this.sanityImg.asset.metadata.dimensions.width
      let heightImage = this.sanityImg.asset.metadata.dimensions.height
      let fromTop = 0
      let fromLeft = 0
      let gapRight = 0
      let gapBottom = 0
      let height = 0
      let width = 0

      if (this.crop) {
        fromTop = parseInt(this.sanityImg.crop.top * heightImage)
        height = heightImage - parseInt(this.sanityImg.crop.top * heightImage)
        fromLeft = parseInt(this.sanityImg.crop.left * widthImage)
        width = widthImage - parseInt(this.sanityImg.crop.left * widthImage)
        gapRight = width - parseInt(widthImage * this.sanityImg.crop.right)
        gapBottom = height - parseInt(heightImage * this.sanityImg.crop.bottom)
      }

      return { fromLeft: fromLeft, fromTop: fromTop, gapRight: gapRight, gapBottom: gapBottom }
    }
  },

  async created() {
    //If we receive only the ref of the image and not the asset we get the image infos from Sanity
    if (this.refImage && !this.asset) {
      await this.$sanity.fetch(getImageDatasFromRef(this.refImage)).then((res) => {
        this.sanityImg.asset = res[0].asset
        this.sanityImg.crop = res[0].crop
        this.sanityImg.hotspot = res[0].hotspot
        this.sanityImg.alt = res[0].title
        this.sanityImg.width = res[0].asset.metadata.dimensions.width;
        this.sanityImg.height = res[0].asset.metadata.dimensions.height;
      });
    }

    //If there is no metada or LQIP enabled in Sanity backend for the pictures we use this LQIP (dev purpose only please)
    if (this.asset && this.asset.metadata == undefined) {
      this.sanityImg.asset.metadata = {}
      this.sanityImg.asset.metadata.lqip = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFElEQVQIW2MMDQ39z8DAwMAIYwAAKgMD/9AXrvgAAAAASUVORK5CYII='
    }
  },

  computed: {
    imageUrl() {
      const builder = imageUrlBuilder(this.$config.api)
      const cropDatas = this.getCropImage()
      return this.hotspot ? builder.image(this.sanityImg.asset).fit('crop').focalPoint(this.sanityImg.hotspot.x, this.sanityImg.hotspot.y).rect(cropDatas.fromLeft, cropDatas.fromTop, cropDatas.gapRight, cropDatas.gapBottom) : builder.image(this.sanityImg.asset)
    },

    imageData() {
      let bpConfig = []
      // this will be the array of 'sm', 'md', 'xl', ... 
      const bpSourceKeys = Object.keys(this.sizes).slice().reverse()
      const bpTailwindConfig = resolveConfig(tailwindConfig).theme.screens

      bpSourceKeys.forEach((key, index) => {
        const minWidth = bpTailwindConfig[key]
        const bpSourceKeyPrevItem = bpSourceKeys[index - 1]
        const bpPrevItemMinWidth = bpTailwindConfig[bpSourceKeyPrevItem]
        const maxWidth = index > 0 ? `${parseInt(bpPrevItemMinWidth) - 1}px` : `${this.config.imageMaxWidth}px`
        const width = Math.round((parseInt(maxWidth) / 100) * this.sizes[key].viewportWidth)
        const height = this.getAspectRatioHeight(width, this.sizes[key].ratio)

        this.sizes[key].key = key
        this.sizes[key].minWidth = minWidth
        this.sizes[key].maxWidth = maxWidth
        this.sizes[key].width = width
        this.sizes[key].height = height

        bpConfig.push(this.sizes[key])

        // Set CSS vars to size the picture element
        this.$nextTick(() => {
          const picture = this.$refs.picture
          if (picture) {
            picture.style.setProperty(`--${key}`, `${(Math.ceil(parseFloat((this.sizes[key].height / this.sizes[key].width) * 100) * 20) / 20).toFixed(2)}%`)
          }
        })
      });

      return {
        sources: bpConfig.slice(0, bpConfig.length - 1),
        img: bpConfig.slice(bpConfig.length - 1, bpConfig.length),
        imgWidth: this.sanityImg.width,
        imgHeight: this.sanityImg.height,
      }
    }
  }
}
