
import { mapGetters } from 'vuex'
export default {
  name: 'FooterComponent',

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  computed: {
    ...mapGetters({
      isLoaderActive: 'ui/isLoaderActive'
    })
  }
}
