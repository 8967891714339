import { groq } from "@nuxtjs/sanity";

export default function getStaticContentQuery(locale) {
  let localizedStaticContent;
  /**
   * TODO: having this method to get the localized navigation
   * is not the best approach. We should reshape the Sanity navigation
   * object to have something more managable, hence more extendable.
   */
  switch (locale) {
    case 'de':
      localizedStaticContent = 'deStatic';
      break;
    case 'fr':
      localizedStaticContent = 'frStatic';
      break;
    case 'it':
      localizedStaticContent = 'itStatic';
      break;
    default:
      localizedStaticContent = 'deStatic';
      break;
  }

  return groq`*[
    _type=="staticContent"
    && !(_id in path("drafts.**"))
  ][0]{
    "sitemap": *[
      _type=="page" &&
      __i18n_lang=="${locale}_CH" &&
      !(_id in path("drafts.**"))
    ] {
      "page": {
        "slug": slug.current,
        __i18n_lang,
        _id
      }
    },
    ${localizedStaticContent}
  }`
}
