
import blocksToHtml from '@sanity/block-content-to-html'
import { getFile } from '@sanity/asset-utils'

export default {
  props: ['text'],

  data() {
    return {
      blocks: {},
      legendBlocks: {}
    }
  },
  mounted() {
    const sitemap = this.$store.state.navigation.sitemap || [];

    try {
      const link = (props) => {
        const isInternalLink = !!props.mark.intLink;
        const isExternalLink = !!props.mark.extLink;
        const isEmail = !!props.mark.eMail;

        if (isExternalLink) {
          return blocksToHtml.h('a', {
            href: props.mark.extLink,
            target: props.mark.target ? '_blank' : '_self',
          }, props.children);
        }

        if (isInternalLink) {
          const _ref = props.mark.intLink._ref;
          const { page } = sitemap.find(({ page }) => page._id === _ref);

          return blocksToHtml.h('a', {
            href: page.slug,
            target: '_self',
          }, props.children);
        }

        if (isEmail) {
          return blocksToHtml.h('a', {
            href: `mailto:${props.mark.eMail}`,
            target: '_self',
          }, props.children);
        }
      }

      const file = (props) => {
        const file = getFile(props.mark.asset._ref, this.$config.api);
        return blocksToHtml.h('a', {
          href: file.asset.url,
          target: '_blank'
        }, props.children);
      }

      if (this.text) {
        // Most likely, this is useless. To be checked
        const blocks = this.text.map((item) => {
          return ({
            ...item,
            _type: 'block'
          })
        })

        this.blocks = blocksToHtml({
          blocks,
          serializers: { marks: { link, file } }
        })
      }
    } catch (e) {
      this.blocks = ''
      console.warn('Blocks content serializer failed!', e)
    }
  }
}
