const getMultilanguageLinkTags = (defaultLocale, appLocales, baseUrl, pageHref, translations) => {
  if (!translations) {
    return;
  }

  // There might be the case where pages are not translated.
  // So the backend response will set for ex: { de: {...}, fr: NULL}
  const availableTranslations = appLocales
    .filter((locale) => locale.code !== translations[locale.code]);

  let links = [];
  availableTranslations.map((locale) => {
    // HOTFIX: || {}; added to avoid error: "Cannot destructure Property of Null if page is not translated"
    const { slug } = translations[locale.code] || '';
    const isHome = slug === "/";

    let href;
    // Business Rule: base locale don't have prefix ex: http://localhost/locale
    if (locale.code === defaultLocale) {
      href = isHome
        ? `${baseUrl}`
        : `${baseUrl}/${slug}`;

      links.push({ rel: 'alternate', hreflang: locale.iso, href });
      links.push({ rel: 'alternate', hreflang: 'x-default', href });
    } else {
      href = (isHome && !!slug)
        ? `${baseUrl}/${locale.code}`
        : `${baseUrl}/${locale.code}/${slug}`;
      
      links.push({ rel: 'alternate', hreflang: locale.iso, href });
    }

  });

  links.push({ rel: 'canonical', href: pageHref });

  return links;
};

export default getMultilanguageLinkTags;