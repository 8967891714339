import { render, staticRenderFns } from "./default.vue?vue&type=template&id=42f2560e"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavPopupOverlay: require('/vercel/path0/components/navigation/PopupOverlay.vue').default,PageHeader: require('/vercel/path0/components/page/Header.vue').default,PageLoader: require('/vercel/path0/components/page/Loader.vue').default,PageFooter: require('/vercel/path0/components/page/Footer.vue').default,PageCookie: require('/vercel/path0/components/page/Cookie.vue').default})
