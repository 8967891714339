import imageUrlBuilder from '@sanity/image-url';

/**
 * If no image is provided, the default one will be loaded.
 * The ogImage.jpg example file, must be placed in the static folder.
 */
const getImagePath = (sanityConfig, baseUrl, sanityImage) => {
  const builder = imageUrlBuilder(sanityConfig);
  return sanityImage
    ? builder.image(sanityImage).url()
    : `${baseUrl}/ogImage.jpg`;
};

export default getImagePath;