import { groq } from "@nuxtjs/sanity";

const getImageDatasFromRef = (ref) => groq`*[_id=="${ref}"]{
  "asset":upload.asset->{
    ...
  },
  "crop":upload.crop,
  "hotspot":upload.hotspot,
  title
}`;

export default getImageDatasFromRef;
