const getSocialMediaMetaTags = (title, description, ogType, pageHref, imagePath) => {
  return [
    { hid: 'card', name: 'twitter:card', content: 'summary_large_image' },
    { hid: 'twitter:title', name: 'twitter:title', content: title },
    { hid: 'twitter:description', name: 'twitter:description', content: description },
    { hid: 'og:title', name: 'og:title', content: title },
    { hid: 'og:description', name: 'og:description', content: description },
    { property: 'og:type', content: ogType },
    { property: 'og:url', content: pageHref },
    { property: 'og:image', content: imagePath },
    { hid: 'image', name: 'twitter:image', content: imagePath }
  ];
};

export default getSocialMediaMetaTags;