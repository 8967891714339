import getNavigationQuery from '~/queries/getNavigationQuery';
import getFooterQuery from '~/queries/getFooterQuery';
import getStaticContentQuery from '~/queries/getStaticContentQuery';
/**
 * Navigation type is:
 * - main: the menu rendered on header (desktop) and overlay (mobile)
 * - top: the menu rendered as top header (desktop) and optionaly on overlay
 * - footer: the menus rendered as widgets on the footer
 * - staticContent: the static content specific for the project
 * - sitemap: will contain all the useful information for building internal link paths
 */

export const state = () => ({
  navigation: {
    primary: [], // Array<InternalLink>
    top: [], // Array<InternalLink>
    footer: [] // this could be an Array<InternalLink>
  },
  staticContent: [],
  locale: '',
  sitemap: []
});

export const mutations = {
  setPrimary(state, navigation) {
    state.navigation.primary = navigation;
  },
  setFooter(state, footer) {
    state.navigation.footer = footer;
  },
  setLocale(state, locale) {
    state.locale = locale;
  },
  setStaticContent(state, staticContent) {
    state.staticContent = staticContent;
  },
  setSitemap(state, sitemap) {
    state.sitemap = sitemap;
  },
};

export const getters = {
  primary(state) {
    return state.navigation.primary;
  },
  footer(state) {
    return state.navigation.footer;
  },
  locale(state) {
    return state.locale;
  },
  staticContent(state) {
    return state.staticContent;
  },
  sitemap(state) {
    return state.sitemap;
  },
  // TODO: make it work, for Richtext component we still need 
  //to get direct access though $store.state.navigation.sitemap
  getPageByRef: (state) => (_ref) => {
    return state.sitemap.filter(({ page }) => page._id == _ref);
  } 
}

export const actions = {
  async loadStaticContents({ commit, state }) {
    const locale = state.locale;
    const query = getStaticContentQuery(locale);
    const result = await this.$sanity.fetch(query);
    
    commit('setSitemap', result.sitemap ?? []);

    switch (locale) {
      case 'de':
        commit('setStaticContent', result.deStatic ?? []);
        break;
      case 'fr':
        commit('setStaticContent', result.frStatic ?? []);
        break;
      case 'it':
        commit('setStaticContent', result.itStatic ?? []);
        break;
      default:
        commit('setStaticContent', result.deStatic ?? []);
        break;
    }
  },
  async loadLocaleNavigation({ commit, state }) {
    const locale = state.locale;
    const navigationQuery = getNavigationQuery(locale);
    const resultNavigation = await this.$sanity.fetch(navigationQuery);

    const footerQuery = getFooterQuery(locale);
    const resultFooter = await this.$sanity.fetch(footerQuery);

    switch (locale) {
      case 'de':
        commit('setPrimary', resultNavigation.navigationMenuDe ?? []);
        commit('setFooter', resultFooter.deFooterNav ?? []);
        break;
      case 'fr':
        commit('setPrimary', resultNavigation.navigationMenuFr ?? []);
        commit('setFooter', resultFooter.frFooternav ?? []);
        break;
      case 'it':
        commit('setPrimary', resultNavigation.navigationMenuIt ?? []);
        commit('setFooter', resultFooter.itFooterNav ?? []);
        break;
      default:
        commit('setPrimary', resultNavigation.navigationMenuDe ?? []);
        commit('setFooter', resultFooter.deFooterNav ?? []);
        break;
    }
  }
}