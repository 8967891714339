import getImagePath from "../utils/getOgImagePath";
import getBaseMetaTags from "../utils/getBaseMetaTags";
import getMultilanguageLinkTags from "../utils/getMultilanguageLinkTags";
import getPageBaseUrl from "../utils/getPageBaseUrl";
import getSocialMediaMetaTags from "../utils/getSocialMediaMetaTags";
import getSchema from "../utils/getStructuredData";
import getPrefetchTags from "../utils/getPrefetchTag";

export default {
  methods: {
    generatePageMetaTags(page) {
      if (!page || !page.meta) {
        return;
      }

      const baseUrl = this.$config.baseURL;
      const sanityConfig = this.$config.api;
      const appLocales = this.$i18n.locales;
      const defaultLocale = this.$i18n.defaultLocale;

      const {
        meta: {
          title = '',
          description = '',
          ogType = 'website',
          image = null,
          noIndex = false,
          noFollow = false,
        },
        slug,
        __i18n_lang,
        translations = undefined,
      } = page;

      const prefetchTags = getPrefetchTags(baseUrl, sanityConfig.projectId);
      const pageHref = getPageBaseUrl(appLocales, baseUrl, slug, __i18n_lang);
      const imagePath = getImagePath(sanityConfig, baseUrl, image);
      const baseMetaTags = getBaseMetaTags(title, description, noIndex, noFollow);
      const linkTags = translations && getMultilanguageLinkTags(
        defaultLocale, appLocales, baseUrl, pageHref, translations
      );
      const schema = getSchema(title, description, pageHref);
      const htmlAttrs = {
        lang: appLocales.find((locale) => locale.iso === __i18n_lang).code,
      }
      const socialMediaMetaTags = getSocialMediaMetaTags(
        title, description, ogType, pageHref, imagePath,
      );
  
      return {
        htmlAttrs,
        title,
        meta: [
          ...prefetchTags,
          ...baseMetaTags,
          ...linkTags,
          ...socialMediaMetaTags
        ],
        script: [
          {
            type: 'application/ld+json',
            json: schema
          }
        ]
      }
    }
  } 
}