
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderComponent',

  computed: {
    ...mapGetters({
      primaryNavigation: 'navigation/primary',
      isOverlayActive: 'ui/isOverlayActive',
      staticContent: 'navigation/staticContent'
    }),

    headerNavigation() {
      return this.buildNavigationTree(this.primaryNavigation)
    },

    activeLocale() {
      return this.$i18n.locale === 'de' ? '' : `/${this.$i18n.locale}`
    }
  }
}
