import config from '~/kickstarter.config'
import Capitalize from '~/mixins/capitalize'
import GetCurrentLocale from '~/mixins/getCurrentLocale'
import ExtractSlug from '~/mixins/extractSlug'
import BuildNavigationTree from '~/mixins/buildNavigationTree'
import GenerateUUID from '~/mixins/generateUUID'
import SetDevmode from '~/mixins/setDevmode'
import SetViewportSizes from '~/mixins/setViewportSizes'
import GeneratePageMetaTags from '~/mixins/generatePageMetaTags'
import ScrollTo from '~/mixins/scrollTo'
import GetLocalePrefix from '~/mixins/getLocalePrefix'
// import IsInViewport from '~/mixins/isInViewport'
import VueGtag from 'vue-gtag'
import Vue from 'vue'

Vue.mixin(Capitalize);
Vue.mixin(GetCurrentLocale);
Vue.mixin(ExtractSlug);
Vue.mixin(BuildNavigationTree);
Vue.mixin(GeneratePageMetaTags);
Vue.mixin(ScrollTo);
Vue.mixin(GetLocalePrefix);

Vue.use(VueGtag, {
  config: { id: config.modules.googleAnalytics.id }
})

if (config.mixins.generateUUID) {
  Vue.mixin(GenerateUUID);
}

if (config.mixins.setDevmode) {
  Vue.mixin(SetDevmode);
}

if (config.mixins.setViewportSizes) {
  Vue.mixin(SetViewportSizes);
}
