/**
 * The last part of 'urlParts' is the activeSlug to request to the backend.
 * pathMatch comes from $route.params
 */
 export default {
  methods: {
    getCurrentLocale(pathMatch) {
      if (!!patchMatch) return;

      const urlParts = pathMatch.split('/')
        .filter((element) => element !== '');

      return urlParts[0];
    },
  }
}
