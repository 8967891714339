export default {
  beforeMount () {
    window.addEventListener('resize', this.resize, false);
  },

  methods: {
    resize () {
      document.documentElement.style.setProperty('--vw', `${window.innerWidth}px`);
      document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
      document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.resize();
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resize, false);
  }
}
