
// @group document
export default {
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  head () {
    return {
      title: "Error Page"
    }
  }
}
