module.exports = {
  title: 'Praxis am Südhang',
  description: 'Sabine Studer - Praxis am Südhang',
  url: {
    dev: 'http://localhost:3000',
    prod: 'https://www.xn--praxis-amsdhang-8vb.ch'
  },
  api: {
    projectId: 'xq09cy6m',
    dataset: 'production',
    apiVersion: '2021-10-11', // Add current date to fix the data api version. This avoids breaking changes over the time.
    useCdn: true
  },
  modules: {
    googleAnalytics: {
      active: true,
      id: 'G-H91QSQRM6W'
    },
    cookieConsent: {
      active: true,
      position: 'bl', // tl = topleft, tr = topright, bl = bottomleft, br = bottomright
      transition: 'fb' // fd = fade, ft = fromTop, fl = fromLeft, fb = fromBottom, fr = fromRight
    },
  },
  mixins: {
    generateUUID: false,
    setDevmode: true,
    setViewportSizes: false,
  },
  i18n: {
    strategy: 'prefix_except_default',
    langDir: '~/locales/',
    vuex: {
      moduleName: 'i18n',
      syncRouteParams: true,
    },
    locales: [
      {
        code: 'fr',
        iso: 'fr_CH',
        file: 'fr.json'
      }, {
        code: 'de',
        iso: 'de_CH',
        file: 'de.json'
      }
    ],
    defaultLocale: 'de',
    detectBrowserLanguage: {
      useCookie: true,
      cookieKey: 'i18n_redirected',
      fallbackLocale: 'de',
      redirectOn: 'root',
    }
  }
}
