import {
    groq
} from '@nuxtjs/sanity';

export default function getFooterQuery(locale) {
    let localizedFooter;
    /**
     * TODO: having this method to get the localized footer navigation
     * is not the best approach. We should reshape the Sanity footer navigation
     * object to have something more managable, hence more extendable.
     */
    switch (locale) {
        case 'de':
            localizedFooter = 'deFooterNav';
            break;
        case 'fr':
            localizedFooter = 'frFooternav';
            break;
        case 'it':
            localizedFooter = 'itFooterNav';
            break;
        default:
            localizedFooter = 'deFooterNav';
            break;
    }

    return groq`*[_type=="footerNavigation" && !(_id in path("drafts.**"))][0] {
        ${localizedFooter}{
            imprint->,
            privacy->,
            conditions->
        }
    }`
}
