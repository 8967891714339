/**
 * This function will return an Array<InternalLink>
 * We parse the data we get from Sanity, and get the
 * minimum amount of info we need to render the links
 * 
 * Example return Array = [
 *  {
 *    {
 *      type: "main",
        slug: 'your-slug'
        title: 'title,
      },
      {
        type: "dropdown",
        title: 'title,
        items: [
          {
            slug: 'second-level-page',
            title: 'Second Level Title',
          }, ...
        ]
      },
      {
        type: "jumplink",
        title: 'Jumplink Title',
        pageSlug: 'page-slug-to-jump-to'
        sectionId: 'sectionId',
      }
 *  }
 * ]
 */
export default {
  methods: {
    buildNavigationTree(internalLinkList) {
      if (!internalLinkList) return;
      
      let navigationTree = [];
      internalLinkList.forEach((link) => {
        const isFirstLevel = !!link.firstLevel;
        const isSecondLevel = !!link.secondLevel && link.secondLevel.length > 0;
        const isJumplink = !!link?.jumplink?.isActive;

        if (isFirstLevel) {
          // TODO: rename this to isHome
          navigationTree.push({
            type: "main",
            slug:
              link.firstLevel?.slug?.current === "/"
                ? ""
                : link.firstLevel?.slug?.current,
            title: link.firstLevel.title,
          });
        }

        if (isSecondLevel) {
          let dropdown = {
            type: "dropdown",
            title: link.title,
            items: [],
          };

          link.secondLevel.forEach((secondLevelLink) => {
            const slug = secondLevelLink.parent
              ? `${secondLevelLink.parent.slug.current}/${secondLevelLink.slug.current}`
              : secondLevelLink.slug.current;

            dropdown.items.push({
              slug,
              title: secondLevelLink.title,
            });
          });

          navigationTree.push(dropdown);
        }

        if (isJumplink && !!link.jumplink.reference) {
          navigationTree.push({
            type: "jumplink",
            title: link.title,
            pageSlug: link.jumplink.reference.slug.current === "/"
              ? ""
              : link.jumplink.reference.slug.current,
            sectionId: link.jumplink.sectionId,
          });
        }
      });

      return navigationTree;
    }
  }
}
