
import getVideoDatasFromRef from '~/queries/getVideoDatasFromRef'

export default {
  data() {
    return {
      videoValues: null
    }
  },
  props: {
    video: {
      type: [String, Object],
      default: null
    },
    sizes: {
      type: Object,
      default() {
        return {
          sm: { viewportWidth: 100, ratio: 'default' },
          md: { viewportWidth: 100, ratio: 'default' },
          lg: { viewportWidth: 100, ratio: 'default' },
          xl: { viewportWidth: 100, ratio: 'default' },
          '2xl': { viewportWidth: 100, ratio: 'default' }
        }
      }
    }
  },
  async created() {
    if (this.video) {
      await this.$sanity.fetch(getVideoDatasFromRef(this.video)).then((res) => {
        this.videoValues = res[0]
      })
    }
  },
  computed: {
    cssProps() {
      return {
        '--ratio-sm': this.sizes['sm'].ratio.replace(':', '/').replace('square', '1/1').replace('panorama', '1409/352'),
        '--ratio-md': this.sizes['md'].ratio.replace(':', '/').replace('square', '1/1').replace('panorama', '1409/352'),
        '--ratio-lg': this.sizes['lg'].ratio.replace(':', '/').replace('square', '1/1').replace('panorama', '1409/352'),
        '--ratio-xl': this.sizes['xl'].ratio.replace(':', '/').replace('square', '1/1').replace('panorama', '1409/352'),
        '--ratio-2xl': this.sizes['2xl'].ratio.replace(':', '/').replace('square', '1/1').replace('panorama', '1409/352')
      }
    }
  }
}
