const getPrefetchTags = (baseUrl, sanityProjectId) => {
  let prefetchTags = [
    { hid: 'preconnect', rel: 'preconnect', href: baseUrl },
    { hid: 'dns-prefetch', rel: 'dns-prefetch', href: baseUrl },
  ];

  if (sanityProjectId) {
    prefetchTags.push(
      { hid: 'preconnect-sanity', rel: 'preconnect', href: `https://${sanityProjectId}.apicdn.sanity.io` },
      { hid: 'dns-prefetch-sanity', rel: 'dns-prefetch', href: `https://${sanityProjectId}.apicdn.sanity.io` },
    )
  }

  return prefetchTags;
};

export default getPrefetchTags;