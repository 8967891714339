/**
 * This method will generate the page for the currently viewed page.
 * Needed mostly for canonical tag, og:url, and schema.
 */
const getPageBaseUrl = (appLocales, baseUrl, slug, __i18n_lang) => {
  // avoid having Url like 'http://localhost:3333//' for base Language
  const isHome = slug.current === '/';
  const pageSlug = isHome
    ? ''
    : '/' + (slug?.current || '');
  const pageLocale = __i18n_lang === 'de_CH'
    ? ''
    : '/' + appLocales.find((locale) => locale.iso === __i18n_lang).code;
  return `${baseUrl}${pageLocale}${pageSlug}`;
}

export default getPageBaseUrl;