import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=c0149dac&scoped=true"
import script from "./ErrorPage.vue?vue&type=script&lang=js"
export * from "./ErrorPage.vue?vue&type=script&lang=js"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=c0149dac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0149dac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Section: require('/vercel/path0/components/elements/Section.vue').default})
