export const SanityContent = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-content.js' /* webpackChunkName: "components/sanity-content" */).then(c => wrapFunctional(c.default || c))
export const SanityFile = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-file.js' /* webpackChunkName: "components/sanity-file" */).then(c => wrapFunctional(c.default || c))
export const SanityImage = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-image.js' /* webpackChunkName: "components/sanity-image" */).then(c => wrapFunctional(c.default || c))
export const DocumentErrorPage = () => import('../../components/document/ErrorPage.vue' /* webpackChunkName: "components/document-error-page" */).then(c => wrapFunctional(c.default || c))
export const DocumentPage = () => import('../../components/document/Page.vue' /* webpackChunkName: "components/document-page" */).then(c => wrapFunctional(c.default || c))
export const DocumentSubPage = () => import('../../components/document/SubPage.vue' /* webpackChunkName: "components/document-sub-page" */).then(c => wrapFunctional(c.default || c))
export const PageCookie = () => import('../../components/page/Cookie.vue' /* webpackChunkName: "components/page-cookie" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/page/Footer.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/page/Header.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/page/Loader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const NavMainOverlay = () => import('../../components/navigation/MainOverlay.vue' /* webpackChunkName: "components/nav-main-overlay" */).then(c => wrapFunctional(c.default || c))
export const NavPopupOverlay = () => import('../../components/navigation/PopupOverlay.vue' /* webpackChunkName: "components/nav-popup-overlay" */).then(c => wrapFunctional(c.default || c))
export const ContentAbout = () => import('../../components/content/About.vue' /* webpackChunkName: "components/content-about" */).then(c => wrapFunctional(c.default || c))
export const ContentCallToAction = () => import('../../components/content/CallToAction.vue' /* webpackChunkName: "components/content-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const ContentContactSection = () => import('../../components/content/ContactSection.vue' /* webpackChunkName: "components/content-contact-section" */).then(c => wrapFunctional(c.default || c))
export const ContentHero = () => import('../../components/content/Hero.vue' /* webpackChunkName: "components/content-hero" */).then(c => wrapFunctional(c.default || c))
export const ContentIllustration = () => import('../../components/content/Illustration.vue' /* webpackChunkName: "components/content-illustration" */).then(c => wrapFunctional(c.default || c))
export const ContentInformation = () => import('../../components/content/Information.vue' /* webpackChunkName: "components/content-information" */).then(c => wrapFunctional(c.default || c))
export const ContentNextService = () => import('../../components/content/NextService.vue' /* webpackChunkName: "components/content-next-service" */).then(c => wrapFunctional(c.default || c))
export const ContentSectionHeader = () => import('../../components/content/SectionHeader.vue' /* webpackChunkName: "components/content-section-header" */).then(c => wrapFunctional(c.default || c))
export const ContentServiceHeader = () => import('../../components/content/ServiceHeader.vue' /* webpackChunkName: "components/content-service-header" */).then(c => wrapFunctional(c.default || c))
export const ContentServicesSlider = () => import('../../components/content/ServicesSlider.vue' /* webpackChunkName: "components/content-services-slider" */).then(c => wrapFunctional(c.default || c))
export const ContentSwiper = () => import('../../components/content/Swiper.vue' /* webpackChunkName: "components/content-swiper" */).then(c => wrapFunctional(c.default || c))
export const ContentTextBlock = () => import('../../components/content/TextBlock.vue' /* webpackChunkName: "components/content-text-block" */).then(c => wrapFunctional(c.default || c))
export const ContentUsageExample = () => import('../../components/content/UsageExample.vue' /* webpackChunkName: "components/content-usage-example" */).then(c => wrapFunctional(c.default || c))
export const BookingButton = () => import('../../components/elements/BookingButton.vue' /* webpackChunkName: "components/booking-button" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/elements/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/elements/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const IntersectionObserver = () => import('../../components/elements/IntersectionObserver.vue' /* webpackChunkName: "components/intersection-observer" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitch = () => import('../../components/elements/LanguageSwitch.vue' /* webpackChunkName: "components/language-switch" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/elements/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const Richtext = () => import('../../components/elements/Richtext.vue' /* webpackChunkName: "components/richtext" */).then(c => wrapFunctional(c.default || c))
export const Section = () => import('../../components/elements/Section.vue' /* webpackChunkName: "components/section" */).then(c => wrapFunctional(c.default || c))
export const MediasPicture = () => import('../../components/elements/Medias/Picture.vue' /* webpackChunkName: "components/medias-picture" */).then(c => wrapFunctional(c.default || c))
export const MediasVideo = () => import('../../components/elements/Medias/Video.vue' /* webpackChunkName: "components/medias-video" */).then(c => wrapFunctional(c.default || c))
export const Medias = () => import('../../components/elements/Medias/index.vue' /* webpackChunkName: "components/medias" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
