/**
 * This state will hold the
 * translation of each slugs for the current page
 */
 export const state = () => ({
  translations: {},
});

export const mutations = {
  setTranslations(state, value) {
    state.translations = value;
  },
};

export const getters = {
  getTranslations(state) {
    return state.translations;
  },
};