/**
 * Useful articles:
 * Regarding offsetTop: https://medium.com/@alexcambose/js-offsettop-property-is-not-great-and-here-is-why-b79842ef7582
 */
export default {
  methods: {
    scrollTo(hash) {
      // the distance of the outer border of the current element
      // relative to the inner border of the top of the offsetParent node.
      const el = document.getElementById(hash.substring(1));
      window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
    }
  }
}