
// @group page
export default {
  props: {
    tag: {
      type: String,
      default: 'section'
    },
    id: {
      type: String,
      default: '',
    },
  }
}
