
// @group elements
export default {
  computed: {
    url() {
      return this.$store.state.navigation.staticContent?.bookButton?.url
    },
    text() {
      return this.$store.state.navigation.staticContent.bookButton.text
    }
  }
}
